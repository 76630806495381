@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* defines the animation */
@keyframes fadeInUp {
    from { 
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
.first {
    opacity: 0; 
    animation: fadeInUp 1s ease-in-out 0s forwards;
    animation-delay: 0.5s;
}

.second {
    opacity: 0; 
    animation: fadeInUp 1s ease-in-out 0s forwards;
    animation-delay: 1s;
}

.third {
    opacity: 0;
    animation: fadeInUp 1s ease-in-out 0s forwards;
    animation-delay: 2s;

}

.fourth {
    opacity: 0;
    animation: fadeInUp 1s ease-in-out 0s forwards;
    animation-delay: 3s;
}
 
html {
    background-color: #111316;
    scroll-behavior: smooth;
}

/* MegaMenu.css */
.menu-item:hover .sub-menu {
  display: block;
}
.sub-menu {
  display: none;
  position: absolute;
  /* Add styling for sub-menu position, background color, etc. */
}



/* .header {
    transition: all 0.5s ease-in-out; 

}

.header.scrolled {
    transition: all 0.5s ease-in-out;
    padding-top: 0;
    background-color: black;
} */

.DisplySC {
    font-family: 'Noto Serif Display', serif;
}

.Pharma {
    background-image: url('../../public/header-right-img.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 1050px;

}

.Risks {
  background-image: url('../../public/Dots.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 1050px;

}

.Riskes {
  background-image: url('../../public/cybercrime-through-the-internet--e1615279069227.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 450px;
}

.Collab {
  background-image: url('../../public/collaboration.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;

}

/* .Pharma {
    animation: star 10s ease-out infinite;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: Pharma;
} */

/* @keyframes Pharma {
    0% {
      opacity: 0;
      transform: scale(1.5) translateY(-0.75em);
    }
    20% {
      opacity: 1;
    }
    89% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: translateZ(-1000em);
    }
  } */
/* slider.css */

.Discovery {
    background-image: url('../../public/Discover.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.Biopharma {
  background-image: url('../../public/Biapharma.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 1050px;
}

.webtech {
  background-image: url('../../public/Web.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 1100px;
}


/* FlipBook container styles */
.flipbook-container {
  width: 800px; /* Adjust the width as needed */
  height: 400px; /* Adjust the height as needed */
  margin: 0 auto;
  position: relative;
}

/* Page styles */
.page {
  width: 100%;
  height: 100%;
  background: #fff; /* Page background color */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; /* Page text size */
  color: #333; /* Page text color */
}

/* Add styles for page corners and animations if desired */


/* Preloader.css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #111316; /* Background color for the preloader */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid #0ec9ac; /* Blue border */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

